<template>
    <section>
        <router-link class="come-back" :to="getLink">
          <div></div>
          {{ $store.getters.GET_LANG ? 'Вернуться в раздел “Все новости”' : 'Go back to the section “All the news”' }}
        </router-link>

        <div class="two-columns-wrapper">
          <main>
            <news-content />
          </main>

          <similar-block-region :title="$store.getters.GET_LANG ? 'Похожие новости' : 'Related news'"
              :getNews="$store.getters.getPublicNews.slice(0,3)"
              link="/news?tab=News"
              card-link="news"
              v-if="$store.getters.getCurrentNews" class="block_region"/>
        </div>

    </section>
</template>
<script>
import NewsContent from '@/components/news/NewsContent'
import SimilarBlockRegion from '@/components/activitiesSurveys/SimilarBlockRegion'
import setMeta from '@/mixins/setMeta'
import translitirate from '@/mixins/translitirate'

export default {
  name: 'News',

  data: () => {
    return {
      params: {}
    }
  },

  watch: {
    '$store.getters.getCurrentNews' () {
      if (this.$store.getters.getCurrentNews) {
        document.title = this.$store.getters.getCurrentNews.title
        this.$store.commit('setFilterRubric', this.$store.getters.getCurrentNews.rubric)
        this.$store.commit('setFilterId', this.$store.getters.getCurrentNews.id)
        this.$store.commit('setFilterWithActual', 1)
        this.$store.commit('setPaginationOffset', 0)
        this.$store.dispatch('getPublicNews')
        this.$store.commit('setFilterRubric', null)
        this.$store.commit('setFilterId', null)
        this.$store.commit('setFilterWithActual', null)
      }
    }
  },

  beforeDestroy () {
    document.title = 'Общественная палата Российской Федерации'
    this.deleteMeta('description')
    this.$store.commit('setCurrentNews', null)
  },

  computed: {
    getLink () {
      const params = this.$store.getters.getRouteParams
      return `${params.path || '/news'}?page=${params.page || 1}${!params.path?.includes('public_chamber_news') ? `&tab=${params.tab || 'News'}` : ''}`
    }
  },

  mixins: [setMeta, translitirate],

  components: {
    SimilarBlockRegion,
    NewsContent
  }
}
</script>
<style lang="scss" scoped>
  @media screen and (max-width: 768px) {
    .come-back {
      width: 90%;
    }
    .block_region{
      display: none;
    }
  }
  @media screen and (max-width: 420px) {
    .two-columns-wrapper {
      flex-direction: column;
    }
  }

</style>
