<template>
  <div>
    <section class="white-container news" v-if="getCurrentNews" id="mainContainer">
        <!-- категория новости и дата -->
        <div class="news-header">
            <p class="news-header__rubric" @click="setRubric()">
              {{ $store.getters.GET_LANG ? getCurrentNews.rubric_model.title : getCurrentNews.rubric_model.title_eng }}
            </p>
            <p class="news-header__date">
              {{ getFormattedDate(getCurrentNews.date_time) }}
            </p>
        </div>

        <!-- заголовок новости -->
        <h5>{{ $store.getters.GET_LANG ? getCurrentNews.title : getCurrentNews.title_eng }}</h5>

        <!-- просмотры и комментарии -->
        <div class="news-info">
          <p v-if="getUserCommentsCount" class="news-info__comment">{{ getUserCommentsCount }}</p>
          <p class="news-info__views">{{ getCurrentNews.view_count }}</p>
        </div>

        <!-- описание новости -->
        <p class="news__description">{{ $store.getters.GET_LANG ? getCurrentNews.description : getCurrentNews.description_eng }}</p>

        <!-- превью общей картинки новости  -->
        <figure v-if="getCurrentNews && getCurrentNews.preview_photo" class="news-img"
            :style="`background: url('${getCurrentNews.preview_photo}') center / cover`">
        </figure>

        <component v-for="(contentBlock, index) in getContentBlocks"
                   :is="contentBlock.component"
                   :content-block="contentBlock"
                   :key="`contentBlock${index}`"
                   class="content__block"/>

        <tricolor-line v-if="getCurrentNews.rubric_model.code === 'ACTUAL_COMMENT'" class="tricolor-line"/>

        <comment-card v-for="(card, i) in getMemberComments"
                    :card="card"
                    :key="`comment${i}`"
                    :listView="false" />
        <!-- новостные теги -->
        <tags-list :convocation-id="getCurrentConvocation" :tags="getTagList" :system-tag-list="getCurrentNews.system_tags_list.indexOf('public_chamber_news') >= 0 ? 'public_chamber_news' : null"/>

        <!-- соц. иконки -->
        <share-social :content="getCurrentNews"/>
    </section>

    <section v-if="getUserCommentsCount" class="white-container comments">
      <p class="title_categories">
        {{$store.getters.GET_LANG ? 'Комментарии' : 'Comments'}}
      </p>
      <tricolor-line />
      <comment-card v-for="(card, i) in getComments" :card="card" :key="`comment${i}`" :listView="false"
                    v-show="(getCurrentNews.rubric_model.code === 'ACTUAL_COMMENT' && !card.author_model.inclusion_ground) || getCurrentNews.rubric_model.code !== 'ACTUAL_COMMENT'"/>
    </section>

    <div class="comment-form white-container" v-if="isToken">
      <h2>
        {{$store.getters.GET_LANG ? 'Форма для отправки комментария' : 'Form for sending a comment'}}
      </h2>

      <label for="comment-text">
        {{$store.getters.GET_LANG ? 'Комментарий' : 'Comment'}}
      </label>

      <input type="text" v-model="commentText" class="input blue" :placeholder="$store.getters.GET_LANG ? 'Ваш комментарий' : 'Your comment'" id="comment-text">

      <button @click="sendMessage" class="btn-blue">
        {{$store.getters.GET_LANG ? 'Отправить' : 'Submit'}}
      </button>
    </div>

    <div class="comment-form white-container" v-else>
      <h2>
        <router-link to="/registration" class="register-link">{{$store.getters.GET_LANG ? 'Зарегистрируйтесь, чтобы оставить комментарий' : 'Register to leave a comment'}}</router-link>
      </h2>
    </div>
  </div>
</template>

<script>
import { Hooper, Slide } from 'hooper'
import ShareSocial from '@/components/news/ShareSocial'
import TricolorLine from '@/components/TricolorLine'
import CommentCard from '@/components/news/CommentCard'
import TextContentBlock from './TextContentBlock'
import RightPicContentBlock from './RightPicContentBlock'
import LeftPicContentBlock from './LeftPicContentBlock'
import TwoPicContentBlock from './TwoPicContentBlock'
import GalleryContentBlock from './GalleryContentBlock'
import formatDate from '@/mixins/formatDate'
import CommentForm from '../activitiesSurveys/CommentForm'
import SocialNetworkIcons from './SocialNetworkIcons'
import TagsList from './TagsList'

export default {
  name: 'NewsContent',

  data () {
    return {
      carouselData: 0,
      views: 86,
      getTempIdNews: 0,
      commentText: '',
      isToken: localStorage.getItem('token') !== null
    }
  },

  mounted () {
    this.$store.dispatch('getPublicNewsById', this.$route.params.id)
    this.$store.dispatch('getPublicNewsComments', this.$route.params.id)
  },

  watch: {
    '$route' () {
      this.$store.dispatch('getPublicNewsById', this.$route.params.id)
      this.$store.commit('setCurrentNews', null)
    },

    '$store.getters.GET_LANG' () {
      this.$store.dispatch('getPublicNewsById', this.$route.params.id)
    },

    getCurrentNews () {
      this.$nextTick(function () {
        this.setLightBoxToImages()
      })
    }
  },

  computed: {
    getCurrentNews () {
      return this.$store.getters.getCurrentNews
    },

    getCurrentConvocation () {
      return this.$store.getters.getCurrentNews?.current_convocation?.id
    },

    getContentBlocks () {
      if (this.getCurrentNews && this.getCurrentNews.content_blocks) {
        return this.getCurrentNews.content_blocks.map((contentBlock) => {
          let component = contentBlock.content_block_type.code.toLowerCase().split('_')

          component = component.reduce((componentName, word) => {
            return componentName + (word && word.charAt(0).toUpperCase() + word.slice(1))
          }, '')

          return {
            ...contentBlock,
            component: component + 'ContentBlock'
          }
        })
      } else {
        return []
      }
    },

    // TEST потом удалить getAllNews
    getAllNews () {
      const content = this.$store.getters.getContent
      return content.content && content.content.news && content.content.news.length ? content.content.news : ''
    },

    getNews () {
      return {
        content: null
      }
    },

    getContentType () {
      const news = this.getNews
      return news.content && news.content.type ? news.content.type : ''
    },

    getContentText () {
      const news = this.getNews
      return news.content && news.content.text ? news.content.text : ''
    },

    getTagList () {
      const news = this.getCurrentNews
      console.log(news)
      return (this.$store.getters.GET_LANG ? news.tags_list : news.tags_list_eng) ? (this.$store.getters.GET_LANG ? news.tags_list : news.tags_list_eng) : []
    },

    getComments () {
      return this.$store.getters.getCurrentNews?.comments || []
    },

    getMemberComments () {
      return this.getComments.filter((comment) => {
        return this.getCurrentNews.rubric_model.code === 'ACTUAL_COMMENT' && comment.author_model.inclusion_ground
      })
    },

    getUserCommentsCount () {
      return this.getComments.length - this.getMemberComments.length
    },

    getImages () {
      const news = this.getNews
      return news.content && news.content.image ? news.content.image : []
    }
  },

  methods: {
    setLightBoxToImages () {
      const contentBlocks = document.querySelectorAll('.content__block');

      [].forEach.call(contentBlocks, (contentBlock) => {
        const images = contentBlock.querySelectorAll('img');

        [].forEach.call(images, (image) => {
          image.addEventListener('click', (e) => {
            this.$store.commit('setLightBoxImage', image.getAttribute('src'))
          })
        })
      })
    },

    async sendMessage () {
      const formData = new FormData()
      formData.append('title', this.commentText)
      formData.append('text', this.commentText)
      await fetch(`${this.$store.getters.getUrlApi}api/user/news/${this.$route.params.id}/comment`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: formData
      })
        .then(response => {
          if (response.ok) {
            this.$store.commit('setMessage', {
              type: 'success',
              text: 'Комментарий отправлен на модерацию'
            })
            this.commentText = ''
          } else {
            this.$store.commit('setMessage', {
              type: 'error',
              text: 'Ошибка отправки комментария'
            })
          }
          // alert('успех')
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })
    },

    setRubric () {
      if (this.getCurrentNews.rubric === 3) {
        this.$router.push('/news?tab=Comment')
      } else {
        this.$store.commit('setFilterRubric', this.getCurrentNews.rubric)
        this.$router.push('/news?tab=News')
      }
    }
  },

  components: {
    TagsList,
    SocialNetworkIcons,
    CommentForm,
    ShareSocial,
    CommentCard,
    TricolorLine,
    Hooper,
    Slide,
    TextContentBlock,
    LeftPicContentBlock,
    RightPicContentBlock,
    TwoPicContentBlock,
    GalleryContentBlock
  },

  mixins: [
    formatDate
  ]
}
</script>

<style lang="scss" scoped>
  .tricolor-line {
    margin-bottom: 2rem;
  }

  .register-link {
    font-size: 1.125rem;
    font-weight: 600;
    color: #003E78;
    mix-blend-mode: normal;
    cursor: pointer;
  }

  .news__description {
    font-style: italic;
    font-weight: normal;
    font-size: .875rem;
    line-height: 1.375rem;
    color: rgba(23, 23, 23, 0.8);
    margin-bottom: 1.625rem;
  }

  .comment-form {

    h2 {
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1.5;
      color: #1F3245;
    }

    label {
      margin-top: 2rem;
      text-align: left;
      font-size: .875rem;
      margin-bottom: .75rem;
      line-height: 1.57;
      color: #1F3245;
    }

    input{
      margin-bottom: 2rem;
    }
  }

.news {
  margin-bottom: 3.125rem;

  .content__block {
    margin-bottom: 1.25rem;
    overflow: hidden;
  }

  h5 {
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;

    margin-bottom: 1.18rem;
  }

  &-header {
    display: flex;
    margin-bottom: 1.18rem;
    align-items: center;

    &__rubric {
      background: #EEF4FA;
      border-radius: 3px;
      font-weight: 600;
      font-size: 0.8125rem;
      line-height: 123%;
      padding: .375rem .625rem;
      text-transform: uppercase;
      color: #003E78;
      opacity: 0.8;
      cursor: pointer;
    }
    &__date {
      font-size: 0.875rem;
      margin-left: 0.625rem;
    }
  }

  &-info {
    display: flex;
    align-items: center;

    &__views,
    &__comment {
      display: flex;
      align-items: center;
      margin-right: 0.75rem;
      margin-bottom: 1.3rem;

      font-weight: 600;
      font-size: 0.8125rem;
      line-height: 125%;

      color: #5A79A8;
    }

    &__views::before{
      background: url(../../assets/icons/view.svg);
    }
    &__comment::before{
      background: url(../../assets/icons/message.svg);
    }
    &__views::before,
    &__comment::before {
      content: '';
      width: 1.3rem;
      height: 1.3rem;
      margin-right: 0.6rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &-content__left-img {
    width: 15rem;
    height: 11.25rem;
    float: left;
    margin: 0 1.56rem 1.56rem 0;

    border-radius: 0.5rem;
  }

  &-content__right-img {
    float: right;
    margin: 0 0 1.56rem 1.56rem;
  }

  ::v-deep &-content__text{
    margin-bottom: 2.5rem;

    p {
      font-size: 0.875rem;
      margin-bottom: 1.25rem;
      line-height: 157%;
      color: rgba(23, 23, 23, 0.8);
    }
  }

  &-tags {
    display: flex;
    flex-wrap: wrap;

    margin-bottom: 2.5rem;

    p {
      margin: 0.35rem 0.625rem 0.35rem 0;
    }
  }

  &-social {
      border-top: 1px solid #D5D6D9;
      padding-top: 2.5rem;
  }

  &-img {
    width: 100%;
    height: 34rem;
    border-radius: 0.5rem;

    margin-bottom: 2.5rem;
  }
  .news-content__gallery {
    display: flex;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
    .slider-nav{
    display: flex;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 42%;

    &__arrows {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 95%;
        margin: 0 auto;

        svg{
            width: 2.5rem;
            height: 2.5rem;

            cursor: pointer;
        }
    }
  }

}

.news__stream {
    margin-top: 1.62rem;
    position: relative;
    margin-bottom: 2.5rem;
  }

  .slider-stream__item {
    display: flex;
  }

  .slider-stream__video {
    width: 55.8rem;
    height: 27.5rem;
    display: flex;
  }

.preview{
    display: flex;
    width: 100%;
    overflow-x: auto;

    .img {
        display: flex;
        min-width: 4.56rem;
        height: 3.31rem;
        border-radius: 0.5rem;
        margin-right: 0.5rem;
        margin-top: 1.25rem;

        background: #9CA0A7;
        opacity: 0.5;
        cursor: pointer;

        &:last-child{
            margin-right: auto;
        }
        &:first-child{
            margin-left: auto;
        }
        &.active-img{
            border: 0.2rem solid #8ABD1F;
            opacity: 1;
        }
    }
}

.comments {
  margin: 3.125rem 0;

  .line_wrapper {
    padding: 1.375rem 0;
  }

}
  @media screen and (max-width: 768px){
    .news {
      .content__block {
        ::v-deep img {
          float: none !important;
        }
      }

      &-img {
        width: 100%;
        height: 21.81rem;
      }

      .news-content__gallery {
        width: 100%;
      }

      .slider-stream__video {
        width: 45rem;
        height: 21.81rem;
      }

    }
  }
   @media screen and (max-width: 420px){
    .news {
      ::v-deep * {
        font-size: 4vw;
      }

      &-header {
        flex-wrap: wrap;
      }

      &-content__left-img {
        width: 100%;
        height: 11.25rem;
        display: block;
        margin: 0 1.56rem 1.56rem 0;
      }

      &-content__right-img {
        width: 100%;
        height: 11.25rem;
        display: block;
        margin: 0 0 1.56rem 1.56rem;
      }

      &-img {
        width: 100%;
        height: 12.5rem;
      }

      &-social {
        padding-top: 1.375rem;
      }

      .slider-stream__video {
        width: 18rem;
        height: 12.5rem;
      }

      .slider-nav{
        display: none;
      }

    }
  }
</style>

<style lang="scss">

  .slider-stream {
    overflow: hidden;
    width: 55.8rem;
    height: 100%;
    border-radius: 0.5rem;

    &.hooper {
        height: 100%;
    }

    .hooper-list {
        .hooper-track{
            display: flex;
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
        }
    }
  }

  .hooper-sr-only {
    display: none;
  }

  @media screen and (max-width: 768px){
    .slider-stream {
      width: 45rem;
    }
  }
  @media screen and (max-width: 420px){
    .slider-stream {
      width: 18rem;
    }
  }

</style>
