export default {
  data () {
    return {
      rus: 'щ   ш  ч  ц  ю  я  ё  ж  ъ  ы  э  а б в г д е з и й к л м н о п р с т у ф х ь'.split(/ +/g),
      eng: "shh sh ch cz yu ya yo zh `` y' e` a b v g d e z i j k l m n o p r s t u f x `".split(/ +/g)
    }
  },

  methods: {
    translitirateText (text, engToRus) {
      let x

      for (x = 0; x < this.rus.length; x++) {
        text = text.split(engToRus ? this.eng[x] : this.rus[x]).join(engToRus ? this.rus[x] : this.eng[x])
        text = text.split(engToRus ? this.eng[x].toUpperCase() : this.rus[x].toUpperCase()).join(engToRus ? this.rus[x].toUpperCase() : this.eng[x].toUpperCase())
      }

      return engToRus ? text.split('-').join(' ') : text
    }
  }
}
